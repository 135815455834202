<template>
  <div
    class="container-xs"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <AppHeader back-to="/profile/edit" />
    <AppDataExclaimer style="margin-top: 24px" />
    <div style="display: grid">
      <x-label for="last-name" tag="label" style="margin-top: 2rem">{{
        $t('form.last-name')
      }}</x-label>
      <form-input
        id="last-name"
        v-model="forms.profile.inputField"
        :pattern="REGEX.NAME"
        minlength="2"
        type="text"
        required
        @valid="forms.vset.profile.inputField"
      />
    </div>
    <x-button
      id="save"
      type="large"
      style="margin-top: auto"
      :disabled="!forms.vget.profile.form"
      :is-loading="isLoading"
      @click="save"
    >
      {{ $t('form.save') }}
    </x-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppDataExclaimer from '../../components-rf/AppDataExclaimer.vue'
import AppHeader from '../../components-rf/AppHeader.vue'
import { REGEX } from '../../constants/REGEX'

export default {
  name: 'EditProfileLastName',
  components: { AppHeader, AppDataExclaimer },
  created() {
    if (this.user) this.forms.profile.inputField = this.user.lastName
  },
  data() {
    return {
      REGEX,
      forms: { profile: { inputField: '' } },
      isLoading: false,
    }
  },
  methods: {
    save() {
      this.isLoading = true
      this.$store
        .dispatch('UserModule/updateUser', {
          lastName: this.forms.profile.inputField,
        })
        .then(() => this.$router.push('/profile/edit'))
        .finally(() => (this.isLoading = false))
    },
  },
  computed: mapState('UserModule', ['user']),
}
</script>
